import { ModalService } from 'src/app/services/modal.service';
import { ApiVersion } from './../../models/api-version';
import { ApiService } from './../../services/api.service';
import { StateService } from 'src/app/services/state.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  portalVersion: string = "1.0.0.11";

  // 1.0.0.11 - 05/23/2022 - AAP-152 - Add code translation (Settings menu option).
  // 1.0.0.10 - 04/22/2022 - AAP-260 - Add sort capability to dataset refresh information.
  // 1.0.0.9  - 04/12/2022 - AAP-200 - Add user last login date
  // 1.0.0.8  - 04/08/2022 - AAP-250 - Add dataset refresh date modal
  // 1.0.0.7  - 03/11/2022 - AAP-243 - Add data refresh date to report container
  // 1.0.0.6  - 02/15/2022 - AAP-193 - Add report rating viewer
  // 1.0.0.5  - 02/08/2022 - AAP-201 - Rename MyReports to MyFavorites
  // 1.0.0.4  - 02/08/2022 - AAP-192 - Add report rating logic
  // 1.0.0.3  - 01/18/2022 - AAP-149 - Configure RESTful urls in customer service
  // 1.0.0.2  - 01/17/2022 - AAP-157 - Report thumbnails
  // 1.0.0.1  - 01/14/2022 - AAP-153 - "About" page + Angular outlet restructure


  apiComponentVersions: ApiVersion[] = [];

  constructor(private stateService: StateService,
              private apiService: ApiService,
              private modalService: ModalService) { }

  ngOnInit() {

    // @ts-ignore
    this.apiService.get(SERVICE_URL + "/version").then(data => {
      this.apiComponentVersions = data['ProcessOutcome']['AssemblyVersions'];
    });
  }

  getDataRefreshDate() {
    return this.stateService.getCustomer().refreshdate;
  }

  onRefreshDateClick() {
    this.modalService.open('app-dataset-viewer', null, ()=>{}, this);
  }
}